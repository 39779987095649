import { notEmpty } from '../typeGuards/shared';

export const getPassengerGroup = (
  booking: PG.Response.Booking.TRootObject,
  userEmail?: string
): PG.Response.Booking.TPassengerGroup => {
  const notCancelled = booking.passengerGroups?.filter(
    (g) => g.cancelled !== true
  );

  return userEmail
    ? notCancelled
        ?.map((px) => {
          if (
            px.passengers.find(
              (em) =>
                em.email.toLocaleLowerCase() === userEmail.toLocaleLowerCase()
            )
          ) {
            return px;
          }
          return null;
        })
        .filter(notEmpty)[0]
    : notCancelled[0];
};

export const getPackageFromBooking = (
  passengerGroup: PG.Response.Booking.TPassengerGroup
): PG.Response.Booking.TPackage | null =>
  passengerGroup?.itinerary?.find(
    (item) =>
      item.type === 'PACKAGE' && item.packageDetails.packageType !== 'ARRIVAL'
  )?.packageDetails ?? null;

export const getArrivalDepaturePackages = (
  passengerGroup: PG.Response.Booking.TPassengerGroup
): PG.Response.Booking.TPackage[] | null =>
  passengerGroup?.itinerary
    ?.filter(
      (item) =>
        item.type === 'PACKAGE' &&
        (item.packageDetails.packageType === 'ARRIVAL' ||
          item.packageDetails.packageType === 'DEPARTURE')
    )
    .map((t) => t.packageDetails) ?? null;

/* Currently the following function is only being called in the 
'createPurchaseEvent' (eventBuilder.ts). Previously, it was calling
getVoyageFromPackageItinerary (below) which was provided an itinerary
assuming that the first itinerary held the voyage details - which
it might not. */
export const getVoyageDetailsFromBooking = (
  passengerGroup: PG.Response.Booking.TPassengerGroup
): PG.Response.Shared.TVoyageDetails | null => {
  /* Within an itinerary (an array) there could be multiple 'PACKAGE's
  the voyage details may not be in the first package returned  */
  const packages =
    passengerGroup?.itinerary.filter((item) => item.type === 'PACKAGE') ?? [];

  /* Return all voyageDetails from all the packages and then
  filter out the null values */
  const voyageDetails = packages
    .map(
      (packageObject) =>
        packageObject?.packageDetails?.packageItinerary.find(
          (i) => i.voyageDetails
        )?.voyageDetails ?? null
    )
    .filter(notEmpty);

  /* hopefully, we only have an array of one... */
  return voyageDetails.length ? voyageDetails[0] : null;
};

export const getVoyageFromPackageItinerary = (
  itinerary: PG.Response.Booking.TPackageItinerary[]
): PG.Response.Shared.TVoyageDetails | null =>
  itinerary.find((item) => item.voyageDetails)?.voyageDetails ?? null;

/* I'm leaving this to be the lead
 passenger for the whole booking as only
 the lead has the clientId matching the invoice */
export const getPayingCustomerFromBooking = (
  booking: PG.Response.Booking.TRootObject
): PG.Response.Booking.TPayingCustomer | null => {
  const notCancelled = booking.passengerGroups?.filter(
    (g) => g.cancelled !== true
  );

  return notCancelled?.[0]?.payingCustomer ?? null;
};
